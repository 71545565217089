@import "~styles/shared";

.ts {
  .attendance-two-col {
    min-height: 110px;
    display: flex;
    align-items: center;

    .attendance-mini-skeleton {
      position: absolute;
      width: 100%;
    }

    h1 {
      font-size: 2rem;
      display: flex;
      margin: 0 0 0.5rem;
      width: 100%;
      min-width: 100%;

      .btn {
        margin-left: auto;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 1.15;
      margin: 0;
    }

    .attendance-two-col-icon {
      margin-right: 2rem;
      flex-shrink: 0;
    }

    .attendance-two-col-text {
      position: relative;
      width: 100%;
      min-height: 100%;
      display: flex;
      align-items: center;
    }

    .attendance-arrow-icon {
      margin-left: 0.5rem;
    }
  }
}
