@import "~styles/shared";

.ts {
  .sidebar-nav.nav-pills {
    flex-direction: column;
    margin-top: 1.5rem;

    @include media-breakpoint-up(md) {
      margin-left: -1.5rem;
      width: calc(100% + 1.5rem);
    }


    @include media-breakpoint-up(xl) {
      margin-left: -4rem;
      width: calc(100% + 4rem);
    }

    @include media-breakpoint-down(md) {
      position: relative;
      padding: 0 1.5rem;
    }


    .nav-link {
      color: $brand-teal-19;
      display: inline-flex;
      background-color: $brand-teal-97;
      padding: 0.75rem 0 0.75rem 1.5rem;
      margin-bottom: 0.25rem;
      border-radius: 0 3rem 3rem 0;
      width: 100%;
      line-height: 1.5rem;
      transition: padding-left 0.3s ease-out;

      i {
        font-size: 1.5rem;
      }

      div {
        margin-left: 0.875rem;
      }

      &:hover {
        background-color: $brand-teal-91;
      }

      &:focus {
        outline: 0;
        box-shadow: $input-btn-focus-box-shadow;
      }

      &.active {
        background-color: $brand-teal-78;
        color: inherit;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 3rem;

        background-color: $brand-teal-91;

        &.active {
          background-color: $brand-teal-91;
        }
      }

      @include media-breakpoint-up(xl) {
        padding-left: 4rem;
      }
    }
  }
}
