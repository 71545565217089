@import "~styles/shared";

$sidebar-width-min: 24rem;

.ts {
  .sidebar-layout {
    position: relative;

    .sidebar {
      position: fixed;

      top: 4rem;
      bottom: 0;
      left: 0;
      transform: translate(-100vw, 0);
      transition: transform 0.3s;
      z-index: 1021; // bootstrap sets pos: sticky to 1020, so we need to be 1 better

      &.show-nav {
        transform: translate(0, 0);

        .sidebar-header {
          &.is-desktop {
            background-color: transparent;
          }
        }
      }

      .sidebar-info-card {
        @include media-breakpoint-up(md) {
          margin-left: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
          margin-left: 4rem;
        }
      }

      .sidebar-nav {
        @include media-breakpoint-up(md) {
          padding-left: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
          padding-left: 4rem;
        }
      }
    }

    .sidebar-content {
      background: $body-bg;
      min-width: 100vw;
      height: 100%;
      min-height: calc(100vh - 4rem);
      overflow: auto;

      @include media-breakpoint-up(md) {
        padding: 0;
        padding-right: 0.75rem;
        width: calc($sidebar-width-min - 4rem);
        min-width: unset;
      }

      @include media-breakpoint-up(xl) {
        padding-right: 2rem;
        width: $sidebar-width-min;
      }
    }

    .sidebar-header {
      position: relative;
      padding: 1.5rem 0 1.5rem 1.5rem;
      background-color: #fff;

      @include media-breakpoint-up(xl) {
        padding-left: 4rem;
      }
    }

    .main-content-header {
      display: flex;
      flex-wrap: wrap;
      min-height: 2.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      justify-content: space-between;

      > * {
        display: flex;
      }

      .sidebar-toggle {
        display: none;

        &.is-mobile {
          display: flex;
        }
      }
    }

    .main-content {
      margin: 0 1.5rem 1.5rem;

      @include media-breakpoint-up(md) {
        margin-left: $sidebar-width-min - 3.25rem;
      }

      @include media-breakpoint-up(xl) {
        margin-left: $sidebar-width-min + 2rem;
        margin-right: 4rem;
      }

      &.bg-no-scroll {
        max-height: calc(100vh - 5.5rem);
        overflow: hidden;
      }

      .details-link-header {
        position: absolute;
        display: inline-block;
      }
    }
  }
}
