@import "~styles/shared";

.ts {
  .heatmap-tile {
    font-weight: 700;
    text-align: center;

    &--month {
      border-radius: 1rem;
      padding: 1rem;
    }

    &--week {
      border-radius: 1rem;
      padding: 0.2rem;
    }

    &--day {
      border-radius: 100%;
    }
  }
}
