@import "~styles/shared";

.ts {
  .sidebar-info-card {
    &.card {
      border-radius: 2rem;
      border: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;

      @include media-breakpoint-up(md) {
        border-radius: 1rem;
        border: solid 1px $brand-gray-90;
      }

      .card-body {
        @include media-breakpoint-down(md) {
          padding-top: 0;
        }
      }

      dl {
        margin-bottom: 0;
        display: grid;
        grid-template-columns: fit-content(50%) auto;
        column-gap: 2rem;

        @include media-breakpoint-up(sm) {
          display: block;
        }

        dt {
          text-transform: uppercase;
          color: $brand-gray-35;
          margin: 0;

          @include media-breakpoint-up(sm) {
            margin-top: 1rem;
          }
        }

        dd {
          margin: 0 0 1rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .sidebar-info-card-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $brand-gray-90;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;

    .sidebar-edit {
      position: absolute;
      top: -4rem;
      right: 0;

      @include media-breakpoint-up(sm) {
        position: unset;
      }
    }
  }
}
