@import "~styles/shared";

.ts {
  .insights-promotional-modal {
    .feature-insights {
      display: grid;
      grid-template-columns: 56px auto;
      gap: 1rem;
      margin: 1rem 0;
      align-items: center;
    }
  }
}
