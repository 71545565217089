@mixin list {
  @apply pl-5;

  li {
    @apply relative;

    &::before {
      @apply absolute;
      @apply leading-relaxed;
      @apply -ml-5;
    }

    ~ li {
      @apply mt-1;
    }
  }
}

@mixin list-en {
  list-style: none;

  li::before {
    content: "–";
  }
}

@mixin list-disc {
  list-style: disc;
}

@mixin list-decimal {
  counter-reset: list-decimal;
  list-style: none !important;

  li {
    counter-increment: list-decimal;

    &::before {
      content: counter(list-decimal) ".";
    }
  }
}

.list {
  @include list;
  @include list-disc;

  &-bold li::before {
    @apply font-bold;
  }

  &--dashed {
    @include list-en;
  }

  &--numbered {
    @include list-decimal;
  }
}
