@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap");

@font-face {
  font-display: block;
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/icomoon.eot?r1de98");
  src: url("../fonts/icomoon.eot?r1de98#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?r1de98") format("truetype"),
    url("../fonts/icomoon.woff?r1de98") format("woff"),
    url("../fonts/icomoon.svg?r1de98#icomoon") format("svg");
}
