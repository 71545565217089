.link {
  @apply cursor-pointer;
  @apply duration-150;
  @apply ease-in-out;
  @apply font-medium;
  @apply no-underline;
  @apply text-indigo-600;
  @apply transition;

  &:hover {
    @apply text-indigo-500;
  }

  &:focus {
    @apply outline-none;
    @apply underline;
  }
}
