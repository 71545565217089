.rmc-picker,
.rmc-picker-light,
.rmc-multi-picker {
  height: 238px; /*34*7*/
}

.rmc-picker,
.rmc-multi-picker {
  background-color: #22252a;
}

.rmc-picker-light {
  background-color: #eee;
}

.rmc-multi-picker {
  align-items: center;
  display: flex;
}

.rmc-picker-item,
.rmc-picker-light-item {
  box-sizing: border-box;
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.rmc-picker-light-item-selected {
  background-color: #dfe2e1;
}

.rmc-picker {
  color: #fff;
}

.rmc-picker-light {
  color: #263d39;
}

.rmc-picker,
.rmc-picker-light {
  display: block;
  flex: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

.rmc-picker-mask {
  background-image: linear-gradient(to bottom, rgba(34, 37, 42, 0.8), rgba(34, 37, 42, 0.6)), linear-gradient(to top, rgba(34, 37, 42, 0.8), rgba(34, 37, 42, 0.6));
}

.rmc-picker-light-mask {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6)), linear-gradient(to top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6));
}

.rmc-picker-mask,
.rmc-picker-light-mask {
  background-position: top, bottom;
  background-repeat: no-repeat;
  background-size: 100% 204px;
  height: 100%;
  left: 0;
  margin: 0 auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.rmc-picker-content,
.rmc-picker-light-content {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.rmc-picker-indicator,
.rmc-picker-light-indicator {
  border-bottom: 1PX solid #ddd;
  border-top: 1PX solid #ddd;
  box-sizing: border-box;
  height: 34px;
  left: 0;
  position: absolute;
  top: 102px;
  width: 100%;
  z-index: 3;
}
