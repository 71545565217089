@import "~styles/shared";

.ts {
  .card-body.premium-feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5rem 2rem;

    .inner-wrapper {
      max-width: 566px;
    }

    .spotlight-icon {
      color: $brand-teal-56;
      background: $brand-teal-91;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 112px;
      height: 112px;
      border-radius: 100%;
      margin-bottom: 2rem;

      i {
        font-size: 80px;
        line-height: 1;
        height: 80px;
        display: block;
      }
    }

    .illustration {
      margin: 5rem auto;
    }
  }

  footer {
    margin-top: 2rem;

    button {
      margin-bottom: 1rem;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }
}
