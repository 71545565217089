@import "~styles/shared";

.ts {
  .sick-event-note {
    background: $brand-teal-97;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 0.5rem;
  }
}
