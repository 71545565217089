@import "~styles/shared";

.ts {
  .stats-grid {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(md) {
      gap: 1rem;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .stat-tile {
    padding: 0.5rem;
    text-align: center;

    .stat-count {
      font-size: 1.5rem;
    }

    .rounded-pill {
      white-space: unset;
    }

    @include media-breakpoint-up(md) {
      border: 1px solid $card-border-color;
      border-radius: 16px;
      padding: 2rem;

      .stat-count {
        font-size: 2rem;
      }

      .rounded-pill {
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 0.5rem 1rem;
      }
    }

    &.selected,
    &:hover {
      background-color: $brand-gray-98;

      &.selected {
        border-color: $brand-teal-56;
        border-width: 3px;
      }

      .rounded-pill {
        outline: solid 1px $white;
      }
    }
  }
}
