.ts {
  .ts-icon {
    height: 30px;
  }

  .employee-message-text {
    font-weight: 400;
  }

  .employee-message-sent {
    font-weight: 400;
    font-size: 0.75rem;
  }

  .card.employee-message-card {
    border: 0;
    border-radius: 0;
    padding: 1rem;
  }
}
