@import "~styles/shared";

.ts {
  .sticky-table {
    position: relative;

    thead {
      position: sticky;
      top: 0;

      @include media-breakpoint-up(sm) {
        top: -1.5rem;
      }

      @include media-breakpoint-up(md) {
        top: -2rem;
      }
    }
  }
}
