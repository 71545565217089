.modal-dialog {
  margin: 0 auto;
  padding: 0;

  @include media-breakpoint-up(sm) {
    padding: 1rem;
  }

  @include media-breakpoint-up(sm) {
    padding: 1.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }
}

.modal-dialog-centered {
  min-height: 100%;
}

.modal {
  .modal-title {
    font-size: $h1-font-size;
  }

  .modal-header {
    padding: 1.5rem;

    @include media-breakpoint-up(sm) {
      padding: 1.5rem 2.5rem;
    }
  }

  .modal-body {
    padding: 1.5rem 1.5rem 0;

    @include media-breakpoint-up(sm) {
      padding: 2.5rem 2.5rem 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    padding: 1rem 1.5rem;
    border: 0;

    @include media-breakpoint-up(sm) {
      padding: 1.5rem 2.5rem;
    }

    .container {
      margin: 0;
      padding: 0;
    }
  }

  .modal-lg {
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: $modal-lg;
    }

    .modal-body {
      padding-bottom: 2.5rem;
    }

    .modal-footer {
      border-top: solid 1px $brand-gray-90;
    }
  }

  .modal-xl {
    width: 100%;

    .modal-body {
      padding-bottom: 2.5rem;
    }

    .modal-footer {
      border-top: solid 1px $brand-gray-90;
    }
  }
}
