[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: never;
  text-transform: none;
}

.icon-upload::before {
  content: "\e900";
}

.icon-user-add::before {
  content: "\e901";
}

.icon-user::before {
  content: "\e902";
}

.icon-check::before {
  content: "\e903";
}

.icon-warning::before {
  content: "\e904";
}

.icon-caret-bottom::before {
  content: "\e905";
}

.icon-caret-top::before {
  content: "\e906";
}

.icon-caret-left::before {
  content: "\e907";
}

.icon-caret-right::before {
  content: "\e908";
}

.icon-filter::before {
  content: "\e909";
}

.icon-sync-error::before {
  content: "\e90a";
}

.icon-sync-synced::before {
  content: "\e90b";
}

.icon-sync-unsynced::before {
  content: "\e90c";
}

.icon-search::before {
  content: "\e90d";
}

.icon-dots::before {
  content: "\e90e";
}

.icon-close::before {
  content: "\e90f";
}

.icon-help::before {
  content: "\e910";
}

.icon-hamburger::before {
  content: "\e911";
}

.icon-send::before {
  content: "\e912";
}

.icon-envelope::before {
  content: "\e93e";
}

.icon-clock::before {
  content: "\e914";
}

.icon-phone::before {
  content: "\e915";
}

.icon-message::before {
  content: "\e916";
}

.icon-notifications::before {
  content: "\e917";
}

.icon-calendar::before {
  content: "\e918";
}

.icon-edit::before {
  content: "\e919";
}

.icon-trash::before {
  content: "\e91a";
}

.icon-info::before {
  content: "\e91b";
}

.icon-plus::before {
  content: "\e91c";
}

.icon-lock::before {
  content: "\e91d";
}

.icon-bulb::before {
  content: "\e93c";
}

.icon-trendup::before {
  content: "\e94c";
}
