@import "~styles/shared";

.ts {
  .fw-filter {
    background: $white;

    .dropdown-menu {
      width: 100%;
    }

    .filter-icon {
      margin-right: -0.5rem;

      @include media-breakpoint-up(md) {
        padding-left: 0.5rem;
      }
    }
  }

  .dropdown {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    .filter-button {
      width: 100%;
      justify-content: space-between;
      color: $brand-gray-35;
    }

    .form-check {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 0 0 1rem;
      margin: 0;

      &:hover {
        cursor: pointer;
      }

      .form-check-input {
        margin: 0;
        min-width: 1em;
      }
    }

    .form-check-label {
      padding: 0;
      display: flex;
      width: 100%;
      padding: 0.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
