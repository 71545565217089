@import "~styles/shared";

.ts {
  .calendar-legend {
    background: #fff;
    padding: 2rem;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-bottom: 1.25rem;
  }

  .calendar-legend-label {
    display: flex;
    align-items: center;
    margin: 0 0 0.75rem 1.5rem;
    font-size: 0.75rem;
    color: $brand-gray-35;

    &-color {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 1.75px;
      margin-right: 0.25rem;
    }
  }

}
