$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "./fonts";
@import "ts-styles/base/variables";

// Inject Tailwind CSSs base styles, including normalization.
@tailwind base;

// Inject component classes registered by tailwind plugins
@tailwind components;

// Inject tailwind utility classes
@tailwind utilities;

@layer base {
  [type="checkbox"],
  [type="radio"] {
    @apply rounded-sm;
    color: $brand-teal-39;

    &:focus {
      box-shadow: none;
    }
  }

  [type="checkbox"]:checked,
  [type="radio"]:checked {
    background-size: unset;
    background-origin: unset;
  }

  input[type="text"],
  textarea {
    border-radius: 0.25rem;
    border-color: $brand-gray-77;

    &:focus {
      border-color: $brand-gray-77;
    }
  }

  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    @apply ring-transparent;
    @apply shadow-none;
  }

  /* stylelint-disable selector-max-id */
  html {
    @apply font-sans;
    @apply font-medium;
    @apply text-hs-dark;
    @apply wk-min-h-screen;
    @apply bg-hs-white-green;
  }

  body,
  #root {
    @apply wk-min-h-screen;
  }

  /* stylelint-enable selector-max-id */
}

// Custom components
@layer components {
  @import "./components/Checkbox/Checkbox";
  @import "./components/DashboardMenuItem/DashboardMenuItem";
  @import "./components/DashboardTab/DashboardTab";
  @import "./components/Link/Link";
  @import "./components/List/List";
  @import "./components/MessageResponseField/MessageResponseField";
  @import "./components/Prose/Prose";
  @import "./components/TextMessage/TextMessage";
  @import "./ts-components/_old/common/button/Button";
  @import "./ts-components/_old/common/content-card/ContentCard";
  @import "./ts-components/_old/common/error/Error";
  @import "./ts-components/_old/common/field/Field";
  @import "./ts-components/_old/common/modal/Modal";
}

@layer utilities {
  .text-4xl {
    line-height: unset;
  }
}

// This exists only to force webpack to include the referenced PNG,
// which is used in our HTML email templates.
.logo-green-png {
  background: url("./images/brand-green-email.png");
}

.logo-dark-green-png {
  background: url("./images/brand-dark-green-email.png");
}

.linkedin-logo-png {
  background: url("./images/linkedin-logo.png");
}

/* stylelint-disable value-no-vendor-prefix */
.wk-min-h-screen {
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
}

/* stylelint-enable value-no-vendor-prefix */

.direction-ltr {
  direction: ltr;
}

.direction-rtl {
  direction: rtl;
}

@keyframes dot {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}

// Custom utilities

.min-h-content {
  min-height: min-content;
}

.grayscale {
  filter: grayscale(1);
}

.overflow-scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

hr.dropdown-break {
  background-color: $table-border-color;
  height: 1px !important;
  margin: 0.5rem 0 !important;
  opacity: 1;
}

/* stylelint-disable property-no-vendor-prefix */
.wk-appearance-none {
  -webkit-appearance: none;
}

.no-outline {
  @apply outline-none;

  &:focus {
    @apply outline-none;
  }
}

/* tooltip */
.tooltip .tooltip-text {
  position: absolute;
  visibility: hidden;
  z-index: 100;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

/* stylelint-disable selector-no-qualifying-type */
.react-datepicker {
  font-family: inherit;
}

.react-datepicker-popper {
  z-index: 10;
}

/* stylelint-disable selector-class-pattern */
.Toastify__toast--success {
  @apply font-sans;
  @apply font-medium;
  @apply bg-hs-green;
  @apply rounded-md;
  @apply p-4;
}

.Toastify__toast--error {
  @apply font-sans;
  @apply font-medium;
  @apply bg-hs-red;
  @apply rounded-md;
  @apply p-4;
}

@media print {
  .page-break {
    page-break-after: always;
    /* Insert Page break for printing */
  }

  .do-not-print {
    display: none;
  }
}

// Classes to be used until tailwind is gone
// bootstrap z-index var
$zindex-fixed: 1030;

.nav-fixer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-fixed;
}

.fixed-nav-offset {
  margin-top: 4rem;
  min-width: 0;
}

.collapse {
  visibility: inherit;
}

* {
  letter-spacing: 0.5px;
}

.MuiAutocomplete-paper .MuiAutocomplete-listbox li[role="option"] {
  font-size: 1rem;
}
