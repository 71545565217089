@import "~styles/shared";

.ts {
  .employees-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    h1 {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    .employees-header-left {
      display: flex;
      align-items: center;
    }

    .employees-header-right {
      text-align: right;
      margin-top: 0.75rem;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }

      > * {
        display: inline-block;
        line-height: 2rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      .header-buttons {
        white-space: nowrap;

        > * {
          display: inline-block;

          &:not(:first-child) {
            margin-left: 1rem;
          }
        }
      }

      .show-deactivated-switch {
        padding-left: 0;
        padding-right: 4rem;

        input {
          float: right;
          margin-left: 0;
          margin-right: -4rem;
        }
      }
    }
  }
}

.MuiDataGrid-paper {
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2) !important;
}

.hide-division-option li[role="option"][data-value="division"],
.hide-groups-option li[role="option"][data-value="groups"],
.hide-team_lead-option li[role="option"][data-value="team_lead"],
.hide-role-option li[role="option"][data-value="role"],
.hide-active_events-option li[role="option"][data-value="active_events"],
.hide-attendance_points-option li[role="option"][data-value="attendance_points"],
.hide-sick_points-option li[role="option"][data-value="sick_points"],
.hide-pto_points-option li[role="option"][data-value="pto_points"],
.hide-vacation_points-option li[role="option"][data-value="vacation_points"],
.hide-flex_points-option li[role="option"][data-value="flex_points"],
.hide-other_points-option li[role="option"][data-value="other_points"] {
  display: none;
}
