.form-control {
  &:disabled {
    color: $brand-gray-60;
    opacity: 1;
    -webkit-text-fill-color: $brand-gray-60;
  }
}

.form-label,
.required-field-notification {
  letter-spacing: 0.1em;
  line-height: 1rem;
  text-transform: uppercase;

  span {
    color: $primary;
    margin-right: 0.23rem;
  }
}

.required-field-notification {
  color: $primary;
}

.custom-radio {
  margin-bottom: 0.5em;
}

.form-check {
  margin-bottom: 0.25rem;

  &.check-lg {
    $form-check-lg-input-width: 1.5rem;
    $form-check-lg-padding-start: $form-check-lg-input-width + 1rem;
    font-size: 1.5rem;
    padding-left: $form-check-lg-padding-start;

    .form-check-input {
      margin-left: $form-check-lg-padding-start * -1;
      margin-top: 0.5rem;
    }

    &.form-switch {
      $form-switch-lg-width: 3rem;
      $form-switch-lg-padding-start: $form-switch-lg-width + 1rem;
      padding-left: $form-switch-lg-padding-start;

      .form-check-input {
        margin-left: $form-switch-lg-padding-start * -1;
      }
    }

    .form-check-label {
      font-size: 1rem;
    }

    .form-text {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  .form-text {
    margin-top: 0;
  }
}

.form-check-label {
  display: inline-flex;
  font-size: 1rem * calc(12/16);
}

.form-text {
  display: block;
  line-height: 1rem;
}
