@import "~styles/shared";

.ts {
  .attendance-table {
    tr {
      vertical-align: middle;

      &.selected {
        background-color: rgba($black, 0.05);
      }

      &:hover {
        cursor: pointer;
      }

      td {
        &.employee-info {
          h3 {
            margin-bottom: 0;
          }

          h6 {
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
            color: $brand-gray-46;
          }
        }

        &.event-dates {
          span {
            display: block;
            white-space: nowrap;
            margin-bottom: 0.25rem;
          }
        }

        &.sync-status {
          text-align: center;

          img {
            display: inline-block;
            height: 1.25rem;
            width: 1.25rem;
          }
        }
      }
    }

    // utilities
    .desktop-only-column {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}
