.status-label {
  @apply
    items-center
    leading-4
    text-sm
    inline-flex;

  .disc {
    @apply
      rounded-full
      inline-block
      w-4
      min-w-4
      mr-2
      h-4;
  }
}


