@import "~styles/shared";

.ts {
  .attendance-sync-status-button {
    white-space: nowrap;

    &.synced {
      border-color: $ui-green-2;
      color: $ui-green-3;

      &:hover {
        background-color: $ui-green-0;
        border-color: $ui-green-2;
        color: $ui-green-3;
      }

      &:disabled {
        background-color: $ui-green-0;
      }
    }



    &.not-synced {
      border-color: $brand-gray-35;
      color: $brand-gray-35;

      &:hover {
        background-color: $brand-gray-94;
        border-color: $brand-gray-35;
        color: $brand-gray-35;
      }

      &:disabled {
        background-color: $brand-gray-94;
      }
    }

    &.changed {
      border-color: $ui-yellow-2;
      color: $ui-yellow-3;

      &:hover {
        background-color: $ui-yellow-0;
        border-color: $ui-yellow-2;
        color: $ui-yellow-3;
      }      
      
      &:disabled {
        background-color: $ui-yellow-0;
      }
    }

  }

  .attendance-sync-status-auto {
    display: flex;
    align-items: center;

    &.synced {
      border-color: $ui-green-2;
      color: $ui-green-3;
    }

    &.not-synced {
      border-color: $brand-gray-35;
      color: $brand-gray-35;
    }
  }
}
