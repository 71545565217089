@import "~styles/shared";

.ts {
  &.error-page {
    margin: 5rem 0;

    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      color: $brand-teal-19;
      text-align: center;
      margin: 3.75rem 0;
    }

    h4 {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: $font-weight-normal;
      color: $brand-teal-19;
      text-align: center;
      margin: 2.5rem 0;
    }

    a {
      font-weight: $font-weight-bold;
      text-decoration: none;
    }

    .btn {
      max-height: 46px;
    }

    .illustration {
      max-height: 350px;
    }
    @include media-breakpoint-up(md) {
      h2 {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    @include media-breakpoint-up(xl) {
      .illustration {
        max-height: 500px;
      }

      h4 {
        margin: 3.5rem 0;
      }
    }
  }
}
