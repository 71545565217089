@import "~styles/shared";

.ts {
  .ts-filter-panel {
    pointer-events: none;

    .is-visible {
      pointer-events: auto;
    }

    .filter-panel-shim {
      background: rgba($brand-gray-46, 0);
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 1040;
      transition: all 0.2s ease-in-out;

      &.is-visible {
        background: rgba($brand-gray-46, 0.3);
      }
    }

    .filter-panel {
      display: flex;
      height: 100%;
      max-height: 100%;
      flex-direction: column;
      width: 286px;
      background: #fff;
      padding: 1.5rem;
      z-index: 1041;
      position: fixed;
      top: 0;
      bottom: 0;
      right: -400px;
      border-bottom-left-radius: 2rem;
      border-top-left-radius: 2rem;
      box-shadow: 2px 4px 8px rgba($brand-gray-46, 0.15),
        0 8px 16px rgba($brand-gray-46, 0.1);
      transition: all 0.3s ease-in-out;

      &.is-visible {
        right: 0;
      }

      header {
        display: flex;
        width: 100%;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: center;

        .h2 {
          margin: 0;
        }

        i {
          font-size: 1.5rem;
        }
      }

      .filter-panel-content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 0.5rem 0.25rem;
      }

      footer {
        margin: 0;
        padding-top: 1rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-shrink: 0;
      }
    }
  }
}
