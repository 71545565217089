@import "~styles/shared";

$survey-header-height: 3rem;
$rounded-pill-height: 2rem;

.bg-teal-39 {
  background-color: $brand-teal-39;
  
  &.button {
    background-color: $brand-teal-39;
  }
}

.bg-teal-97 {
  background-color: $brand-teal-97;

  &.button {
    background-color: $brand-teal-97;
  }
}

.bg-teal-91 {
  background-color: $brand-teal-91;

  &.button {
    background-color: $brand-teal-91;
    color: $brand-teal-39;
  }
}

.bg-teal-97 {
  background-color: $brand-teal-97;
}

.drop-shadow-container {
  border: 1px solid var(--grayscale-94, #EFF0F0);
  border-radius: 1rem;
  box-shadow: 0 4px 12px 0 rgba(72, 127, 120, 0.05), 2px 2px 6px 0 rgba(72, 127, 120, 0.08);
  padding: 1.5rem;
}

.attendance-history-timeline {
  min-height: 100vh;
}

.attendance-history {
  background-color: $white;
  color: $brand-teal-19;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  > div {
    // This is required to keep the content centered vertically with dynamic longer content that could fill the viewport
    max-width: 100%;
    overflow-y: auto;
  }

  header {
    align-items: center;
    background-color: $white;
    border-bottom: solid 1px $brand-gray-77;
    color: $brand-gray-46;
    position: fixed;
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    height: $survey-header-height;
    left: 0;
    padding: 0 1.5rem;
    text-transform: uppercase;
    top: 0;
    width: 100%;
  }

  .stat-count {
    font-size: 2rem;
    color: $black;
  }

  .attendance-events {
    display: grid;
    max-width: 500px;
    margin: 1rem auto;
    grid-template-columns: 1fr;
    gap: 1rem;

    > div {
      width: 100%;

      .rounded-pill {
        align-items: center;
        border-radius: $rounded-pill-height / 2;
        display: flex;
        font-weight: $font-weight-bold;
        height: fit-content;
        justify-content: center;
        line-height: 1rem;
        min-height: $rounded-pill-height;
        padding: 6px 16px;
      }
    }
  }
}

.invisible-anchor {
  position: relative;
  bottom: 3rem;
}

@media screen and (min-width: 400px) {
  .attendance-history {
    .attendance-events:not(.event-count-1) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .attendance-history {
    .attendance-events:not(.event-count-1):not(.event-count-2) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}


