$breakpoint-xs: 375px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$login-gap: 30px;
$login-small-gap: calc(#{$login-gap} / 2);
$link-color: #487f78;
$link-hover-color: #2e524d;

.login-page {
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  a, .link {
    color: $link-color;
    text-decoration: none;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $link-hover-color;
      text-decoration: none;
    }

    &.question-link {
      display: inline-block;
      margin: $login-gap 0 0 0;

      &::before {
        border: solid 1px $link-color;
        border-radius: 50%;
        content: "?";
        display: inline-block;
        height: 16px;
        line-height: 16px;
        margin: 0 5px 0 0;
        width: 16px;
      }
    }
  }

  .form-column {
    display: grid;
    min-height: 100vh;

    main {
      align-self: end;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 2px 2px 2px rgba(95, 99, 104, 0.1), 0 4px 4px rgba(95, 99, 104, 0.15);
      justify-self: center;
      max-width: 375px;
      padding: $login-gap;
      text-align: center;
      width: 90%;

      .ts-mark {
        margin: 0;

        img {
          display: inline;
          height: 60px;
          width: 60px;
        }
      }

      .ts-logo {
        margin: $login-gap 0 calc($login-gap + 10px) 0;

        img {
          display: inline;
          height: auto;
          max-width: 100%;
          width: 190px;
        }
      }

      form {
        .field-set {
          margin: 0 0 $login-small-gap 0;
          width: 100%;
        }

        .field-label {
          text-transform: uppercase;
        }

        button {
          height: 48px;
          width: 100%;
        }
      }
    }
  }

  .illustration {
    display: none;
    min-height: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    background-position: center left;
    bottom: 0;
    left: calc(50% - 32px);

    img {
      height: auto;
      max-width: none;
      min-height: 100%;
      min-width: 100%;
      width: auto;
    }
  }

  footer {
    align-self: end;
    justify-self: center;
    width: 90%;

    .ts-footer {
      .ts-footer-inner {
        gap: $login-small-gap;
        justify-content: center;
        margin: 0;
        padding: $login-small-gap 0;

        h5 {
          margin: 0 0 $login-small-gap 0;
        }

        a {
          margin: 0;
        }
      }

      .footer-right {
        h5 {
          z-index: auto;
        }
      }
    }
  }
}


@media screen and (min-width: $breakpoint-xs) {
  .login-page {
    .form-column {
      main {
        .ts-mark {
          img {
            height: 88px;
            width: 88px;
          }
        }

        .ts-logo {
          img {
            height: auto;
            width: 226px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .login-page {
    .form-column {
      background-color: #ffffff;
      border-radius: 0 32px 32px 0;
      box-shadow: 2px 4px 24px rgba(95, 99, 104, 0.16), 0 8px 32px rgba(95, 99, 104, 0.08);
      display: grid;
      max-width: none;
      width: 50%;

      main {
        border-radius: 0;
        box-shadow: none;

        .form-container {
          margin: 0;
        }
      }


    }

    .illustration {
      display: block;
    }

  }
}

@media screen and (min-width: $breakpoint-lg) {
  .login-page {
    footer {
      .ts-footer {
        .ts-footer-inner {
          h5 {
            margin: 0;
          }
        }
      }
    }

  }
}
