@import "~styles/shared";

.ts {
  .languages-dropdown {
    margin: 2rem 0;

    h6 {
      margin-bottom: 1rem;
      color: $brand-gray-46;
    }
  }
}

