.button {
  @apply appearance-none;
  @apply bg-white;
  @apply border-gray-300;
  @apply border;
  @apply cursor-pointer;
  @apply duration-150;
  @apply ease-in-out;
  @apply font-medium;
  @apply inline-flex;
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  @apply leading-5;
  @apply px-4;
  @apply py-2;
  @apply rounded-md;
  @apply shadow-sm;
  @apply text-hs-dark;
  @apply text-sm;
  @apply transition;
  @apply truncate;

  // Sizes

  &-sm {
    @apply px-2.5;
    @apply py-1.5;
    @apply text-xs;
    @apply leading-4;
  }

  &-lg {
    @apply px-6;
    @apply py-3;
    @apply text-base;
    @apply leading-6;
  }

  // Buttons states

  &--disabled {
    @apply cursor-not-allowed;
    @apply grayscale;
    @apply bg-gray-100;
    @apply pointer-events-none;
  }

  &:hover {
    @apply text-gray-500;
  }

  &:focus {
    @apply border-blue-300;
    @apply outline-none;
  }

  &:active {
    @apply bg-gray-50;
    @apply text-gray-800;
  }

  &--stealth {
    @apply bg-transparent;
    @apply p-2;
    @apply text-gray-400;

    &:hover,
    &:focus {
      @apply bg-gray-100;
      @apply text-gray-500;
    }
  }

  // Themes

  &--primary {
    @apply border-transparent;
    @apply bg-hs-green;
    @apply text-white;

    &:hover {
      @apply bg-hs-dark;
      @apply text-white;
    }

    &:focus {
      @apply border-indigo-700;
      @apply shadow-outline-indigo;
    }

    &:active {
      @apply bg-indigo-700;
    }
  }

  &--secondary {
    @apply border-transparent;
    @apply bg-hs-dark;
    @apply text-white;

    &:hover {
      @apply bg-indigo-50;
      @apply text-indigo-700;
    }

    &:focus {
      @apply border-indigo-300;
      @apply shadow-outline-indigo;
    }

    &:active {
      @apply bg-indigo-200;
    }
  }

  &--destructive {
    @apply border-transparent;
    @apply bg-hs-red;
    @apply text-white;

    &:hover {
      @apply bg-hs-dark-red;
      @apply text-white;
    }

    &:focus {
      @apply border-red-300;
      @apply shadow-outline-red;
    }

    &:active {
      @apply bg-red-200;
    }
  }

  &--stealth {
    @apply border-none;
    @apply shadow-none;
    @apply px-2;
    @apply text-gray-400;

    &:hover {
      @apply text-gray-500;
      @apply bg-gray-50;
    }

    &:focus {
      @apply bg-gray-100;
      @apply shadow-none;
    }

    &-sm {
      @apply px-1.5;
    }

    &-lg {
      @apply px-3;
    }
  }

  // Layouts

  &__icon + &__label {
    @apply ml-2;
  }

  &__label + &__icon {
    @apply ml-2;
  }

  // Button Group
  // Buttons merged into a toolbar

  &-group {
    @apply inline-flex;
    @apply relative;
    @apply z-0;

    .button {
      &:focus,
      &:active {
        @apply z-10;
      }
    }

    &__left {
      @apply rounded-r-none;
    }

    &__middle {
      @apply rounded-none;
      @apply -ml-px;
    }

    &__right {
      @apply rounded-l-none;
      @apply -ml-px;
    }
  }

  // Button Set
  // Buttons that are groups but not merged

  &-set {
    @apply flex;
    @apply flex-col;
    @apply w-full;
    @apply items-stretch;

    @screen sm {
      @apply flex-row;
      @apply items-center;
    }

    .button + .button {
      @apply mt-2;

      @screen sm {
        @apply mt-0;
        @apply ml-3;
      }
    }

    &--right {
      @apply justify-end;

      .button + .button {
        @screen sm {
          @apply ml-0;
          @apply mr-3;
        }
      }
    }
  }
}
