@import "~styles/shared";

.ts {
  .status-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;

    h1 {
      margin: 0;
    }

    .btn {
      padding: 0 0.5rem;
    }

    .icon-trash {
      font-size: 2rem;
    }
  }

  .employee-status-notes {
    .ts-input {
      .form-control {
        max-height: unset;
      }
    }
  }

  .employees-delete-btn.btn {
    padding: 0 1rem;

    i {
      font-size: 2rem;
      margin: 0 auto;
      display: block;

      @include media-breakpoint-up(sm) {
        margin: 0 -0.25rem 0 0.25rem;
      }
    }
  }
}
