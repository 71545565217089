@import "~styles/shared";

.ts {
  .attendance-sync-status {
    .status-message {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .status-message-gray {
      color: $brand-gray-46;
    }

    .status-message-red {
      color: $ui-red-3;
    }

    .sync-status-icon {
      height: 1rem;
    }
  }
}
