.container,
.container-fluid {
  &.standard-page {
    padding: 1.5rem;

    @include media-breakpoint-up(lg) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}
