/* stylelint-disable selector-no-qualifying-type */
div.datepicker.dark {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: initial;
  font-family: inherit;
  margin-bottom: 30px;
  margin-top: 10px;
  position: relative;
  width: 87%;
}

div.datepicker.dark div.datepicker-wheel {
  border-bottom: 1px solid #01c16b;
  border-top: 1px solid #01c16b;
}

div.datepicker.dark ul.datepicker-scroll li {
  color: #fff;
  font-size: inherit;
}

div.datepicker.dark div.datepicker-caption-item {
  color: #fff;
  font-size: inherit;
  font-weight: bold;
}

div.datepicker.default {
  background-color: transparent;
}
