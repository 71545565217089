@import "~styles/shared";

.ts {
  .perfect-attendance-table {
    .pa-employee-name {
      span {
        display: block;

        &.pa-id {
          font-size: 0.75rem;
          color: $brand-gray-46;
        }
      }
    }

    tr {
      &:hover {
        cursor: pointer;
      }
    }

    .pa-no-results {
      text-align: center;
      padding: 3rem 0;
    }

    td {
      h5 {
        margin: 0;
      }

      h6 {
        margin: 1rem 0 0;
      }
    }
  }
}
