@import "~styles/shared";

.ts {
  .calendar-tooltip {
    display: block;
    position: absolute;
    padding: 1rem;
    top: 50px;
    background: #fff;
    z-index: 200;
    border: solid 1px $brand-gray-94;
    box-shadow: 2px 4px 4px rgba($brand-gray-94, 0.1), 0 8px 8px rgba($brand-gray-94, 0.5);
    left: 20px;
    pointer-events: none;
  }

  .tooltip-legend {
    .tooltip-legend-label {
      white-space: nowrap;
      font-size: 0.75rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
