.ts-select {
  appearance: none;

  background-image: url('data:image/svg+xml,%3Csvg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 1L6 6L11 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 0.75rem;
  outline: 0;

  @apply rounded-md;
  @apply shadow-sm;

  @apply pl-5;
  @apply pr-10;
  @apply py-2;

  // TODO: add styling for focus/disabled/etc.
}
