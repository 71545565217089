@mixin ripple($color) {
  perspective: 1px;

  &::before {
    background-image: radial-gradient(circle, $color 1%, transparent 1%);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 15000%;
    border-radius: inherit;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.01;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: background 0.8s, opacity 1s;
    width: 100%;
    z-index: -1;
  }

  &:active::before {
    background-size: 1%;
    opacity: 0.99;
    transition: 0s !important;
  }
}

.btn + .btn {
  margin-left: 0.5rem;
}

.btn-primary {
  @include ripple($brand-teal-56);

  &:focus,
  &:active,
  &:hover {
    /* stylelint-disable-next-line scss/no-global-function-names */
    background-color: change-color($brand-teal-39, $lightness: 25%);
  }
}

.btn-secondary {
  @include ripple($brand-teal-56);
  background-color: $brand-teal-91;
  border-color: $brand-teal-91;
  color: $brand-teal-39;

  &:focus,
  &:active,
  &:hover {
    color: $brand-teal-19;
    /* stylelint-disable-next-line scss/no-global-function-names */
    border-color: change-color($brand-teal-78);
    /* stylelint-disable-next-line scss/no-global-function-names */
    background-color: change-color($brand-teal-78);
  }
}

.btn-outline-primary {
  @include ripple($brand-teal-78);
  background-color: $white;

  &:focus,
  &:active,
  &:hover {
    background-color: $brand-teal-91;
    color: $brand-teal-39;
  }
}

.btn-link {
  @include ripple($brand-teal-78);
  color: $brand-teal-39;
  text-decoration: none;

  &:focus,
  &:active,
  &:hover {
    background-color: $brand-teal-91;
    color: $brand-teal-39;
  }

  &:focus {
    background-color: $brand-teal-91;
  }
}

.btn-danger {
  @include ripple($ui-red-1);
  background-color: transparent;
  border-color: transparent;
  color: $danger;

  &:focus,
  &:hover,
  &:active {
    background-color: $ui-red-0;
    border-color: transparent;
    color: $danger;
  }
}

.btn-gray-outline {
  border-color: $brand-gray-77;
}

.btn-primary,
.btn-outline-primary,
.btn-link,
.btn-danger {
  position: relative;

  &:disabled {
    background-color: $brand-gray-90;
    border-color: transparent;
    color: $brand-gray-20;
  }
}

// set button height via line-height and
// removed top/bottom padding to make
// buttons work with the icomoon icons
.btn {
  align-items: center;
  display: inline-flex;
  line-height: 1rem * calc(38/16);
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  font-weight: 700;

  .btn-icon {
    font-size: 1.5rem;

    &.btn-icon-start {
      margin-left: -0.5rem;
      margin-right: 0.25rem;
    }

    &.btn-icon-end {
      margin-left: 0.25rem;
      margin-right: -0.5rem;
    }
  }

  .spinner-border {
    color: $brand-teal-39;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
  }

  &.btn-loader:disabled {
    background-color: $brand-gray-94;
    border-color: transparent;
    color: $brand-gray-77;
    opacity: 1;
  }

  &-sm {
    line-height: 1rem * calc(30/16);

    .btn-icon {
      &.btn-icon-start {
        margin-left: -0.75rem;
      }

      &.btn-icon-end {
        margin-right: -0.75rem;
      }
    }
  }

  &-lg {
    line-height: 1rem * calc(46/16);

    .btn-icon {
      &.btn-icon-start {
        margin-left: -1rem;
      }

      &.btn-icon-end {
        margin-right: -1rem;
      }
    }

    .btn-icon {
      font-size: 2rem;
    }
  }
}
