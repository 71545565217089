@import "~styles/shared";

.ts {
  .ainr-no-results {
    text-align: center;

    .unlock-icon {
      display: inline-block;
      margin: 0.5rem auto 1rem;
      width: 68px;
    }

    p {
      margin-bottom: 1rem;
    }
  }
}
