@import "~styles/shared";

.ts {
  .employee-calendar-nav {
    display: flex;
    justify-content: center;
    max-width: 587px;
    margin: 1rem 0 0;

    i {
      font-size: 2rem;
    }
  }

  .employee-calendars {
    max-width: 1088px;

    @include media-breakpoint-up(xxl) {
      display: grid;
      grid-template-columns: 5fr 4fr;
      grid-template-rows: auto;
      gap: 1rem;
      align-items: center;
    }
  }

  .employee-calendar {
    background: #fff;
    border: solid 1px $brand-gray-77;

    &.current-month {
      @include media-breakpoint-up(md) {
        max-width: 577px;
      }

      @include media-breakpoint-up(xxl) {
        max-width: unset;
      }
    }

    &.next-month {
      margin-top: -48px; // height set to match height of calendar nav
    }

    header {
      padding: 2rem;
      border-bottom: solid 1px $brand-gray-77;

      * {
        margin: 0;
      }
    }

    .react-calendar__viewContainer {
      padding: 2rem;
    }

    .react-calendar__month-view__weekdays__weekday {
      opacity: 0.5;
      font-weight: 700;
      text-align: center;
      padding: 1rem 0;

      &:hover {
        cursor: default;
      }

      abbr[title] {
        cursor: default;
        text-decoration: none;
      }
    }

    .react-calendar__month-view__days,
    .react-calendar__month-view__weekdays {
      display: grid !important; // override plugin default
      gap: 0.5rem;
      grid-template-columns: repeat(7, 1fr);
    }


    .react-calendar__tile {
      position: relative;
      box-sizing: border-box;
      overflow: visible !important;

      &:focus-visible {
        outline: none;
      }

      &:nth-of-type(7n) {
        .calendar-shape {
          box-shadow: none !important;
        }
      }

      &:nth-of-type(n+5):nth-of-type(-n+7),
      &:nth-of-type(n+12):nth-of-type(-n+14),
      &:nth-of-type(n+19):nth-of-type(-n+21),
      &:nth-of-type(n+26):nth-of-type(-n+28),
      &:nth-of-type(n+33):nth-of-type(-n+35),
      &:nth-of-type(n+40):nth-of-type(-n+42) {
        .calendar-tooltip {
          right: 1.25rem;
          left: auto;
        }
      }


      &.react-calendar__tile--now {
        .calendar-day-bg {
          background: $brand-gray-90;
        }
      }

      &:hover {
        .event-info-popup {
          display: block;
        }
      }

      abbr {
        display: block;
        opacity: 0;
        width: 100%;
        height: 0;
        padding-top: 100%;
      }

      .calendar-day-bg {
        position: absolute;
        background: $brand-gray-98;
        height: 100%;
        width: 100%;
        top: 0;
        border-radius: 100%;
      }

      .current-day {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 100;
        font-weight: 700;
      }

      &.react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.3;

        .current-day {
          background: none;
        }
      }
    }
  }
}








