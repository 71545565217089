$breakpoint-lg: 992px;

.user-account {
  .card {
    height: 100%;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .account-inputs {
    max-width: 400px;
  }
}
