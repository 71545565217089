@import "~styles/shared";

.ts {
  .attendance-coming-soon {
    text-align: center;
    margin-top: 2rem;

    header {
      img {
        margin-top: 2rem;
      }
    }
  }

  .attendance-responsive-image {
    img {
      display: block;
      margin: 0 auto;
    }
  }
}
