@import "~styles/shared";

.ts {
  .employees-table {
    border-style: none !important;

    tr {
      cursor: pointer;
    }

    td {
      :last-child {
        margin-bottom: 0;
      }
    }

    .employee-info {
      h3 {
        margin-bottom: 0;
      }

      h6 {
        color: $brand-gray-46;
      }
    }
  }
}
