@import "~styles/shared";

.ts {
  .heatmap-weekly-grid {
    display: grid;
    gap: 0.5rem;
  }

  .attendance-heatmap-legend {
    display: flex;
    justify-content: center;

    > div {
      margin: 0 0.5rem;
    }
  }
}
