@import "~styles/shared";

.ts {
  .insights-popover {
    color: $brand-teal-19;

    h6 {
      font-size: 0.875rem;
    }

    p {
      line-height: 1.2;
      font-size: 0.75rem;

      &:is(.mb-0) {
        font-size: 0.875rem;
      }
    }
  }

  .label-bar-insights {
    min-width: 128px;
    font-size: 0.75rem;
    white-space: nowrap;
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    .legend-square {
      width: 8px;
      height: 8px;
      display: block;
      border-radius: 2px;
    }
  }
}
