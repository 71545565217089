@import "~styles/shared";

.ts {
  .helper-text {
    display: block;
    margin: -0.5rem 0 0 2.5rem;
    color: $brand-gray-46;
    font-size: 0.75rem;
  }
}
