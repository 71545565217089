@import "~styles/shared";

// re-usable snippet for adding underline on focus
%navbar-focus {
  outline: none;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background: transparent;
    transition: height 0.3s ease, background-color 0.3s ease;
  }

  &:focus {
    &::before {
      height: 4px;
      background: $white;
    }
  }
}

.ts {
  .navbar.ts-navbar {
    padding-bottom: 0;
    padding-top: 0;
    min-height: 4rem;
    max-height: 4rem;

    @include media-breakpoint-up(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .badge {
      margin-left: 0.5rem;
      font-size: 0.625rem;
      line-height: 1;
    }

    .container-fluid {
      justify-content: unset;
    }

    .navbar-brand {
      @extend %navbar-focus;
      padding: 0;
      height: 4rem;
      display: flex;
      margin-right: 1rem;
    }

    .navbar-nav {
      &.right-links {
        .nav-link {
          margin-left: 1.5rem;
          @include media-breakpoint-up(xl) {
            margin-left: 2rem;
          }
        }
      }

      .dropdown-item {
        display: flex;
        align-items: center;
      }

      .nav-link {
        @extend %navbar-focus;
        align-items: center;
        display: inline-flex;
        margin-left: 2rem;
        padding: 0;
        line-height: 4rem;
        border-width: 0;
      }

      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }

    .navbar-toggler {
      @extend %navbar-focus;
      border-width: 0;
      outline: none;
      padding: 0;
      color: #fff;
      font-size: 2rem;
      line-height: 4rem;
      height: 4rem;
      margin-right: 1rem;
    }

    .collapsed-help {
      margin-left: auto;

      @include media-breakpoint-up(lg) {
        display: none;
      }

      .nav-link {
        margin: 0;
        padding: 0;
      }
    }
  }

  .teamsense-logo {
    width: 8.25rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  .left-links {
    margin-right: auto;
  }

  .icon-help {
    font-size: 2rem;
    line-height: 4rem;
  }

  .navbar-collapse {
    &.show,
    &.collapsing {
      .navbar-nav {
        display: none;
      }
    }

    &.collapse {
      visibility: inherit;
    }
  }

  .navbar-help {
    display: flex;
  }

  .navbar-user {
    display: flex;
    align-items: center;

    .icon-caret-bottom {
      margin-left: 0.5rem;
      font-size: 1.5rem;
      line-height: 4rem;
    }
  }

  .navbar-help-label,
  .navbar-user-name {
    margin-right: 0.75rem;
    display: none;

    @include media-breakpoint-up(xl) {
      display: inline-flex;
    }
  }

  .navbar-initials {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    line-height: 1;
    border-radius: 50%;
    background: $brand-teal-56;
    color: $white;
  }

  // mobile nav
  .offcanvas {
    position: relative;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;

    .offcanvas-header {
      padding-bottom: 0;
    }

    .offcanvas-title {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 700;

      .navbar-initials {
        font-weight: 500;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 1.125rem;
        text-transform: uppercase;
      }

      .navbar-user-name {
        font-size: 1rem * calc(20 / 16);
        line-height: 1.5rem;
        display: block;
      }
    }

    .offcanvas-close {
      display: block;
      position: absolute;
      padding: 0;
      right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 2rem;
      line-height: 2.5rem;
      border-radius: 50%;
      background-color: #fcc;
      background-color: $secondary;
      transition: background 0.3s, opacity 0.3s;

      &:focus,
      &:active,
      &:hover {
        outline: none;
        /* stylelint-disable-next-line scss/no-global-function-names */
        background-color: change-color($secondary, $lightness: 25%);
      }
    }

    .offcanvas-body {
      padding-top: 1rem;

      // mask the bottom with a gradient to show there is more if you scroll
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3rem;
        background: linear-gradient(
          rgba($secondary, 0) 0%,
          rgba($secondary, 1) 100%
        );
        border-bottom-right-radius: 1.5rem;
      }

      .nav-link {
        @extend %navbar-focus;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        display: block;
        font-size: 1rem;
        color: $navbar-dark-color;

        &:hover,
        &:focus {
          color: $navbar-dark-hover-color;
        }

        &.disabled {
          color: $navbar-dark-disabled-color;
        }
      }

      .show > .nav-link,
      .nav-link.active {
        color: $navbar-dark-active-color;
      }

      .offcanvas-line-break {
        border-top: 1px solid $brand-gray-94;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
    }
  }
}
