@import "~styles/shared";

.ts {
  .form-schedules {
    td {
      &.schedule-name {
        width: 40%;
      }

      &.schedule-time {
        width: 10%;

        h6 {
          margin: 0;
          text-transform: uppercase;
          color: $brand-gray-46;
          line-height: 1.5rem;
        }
      }

      &.schedule-schedule {
        width: 25%;
      }

      &.schedule-recipients {
        width: 25%;
      }
    }

    .frequency-label {
      text-transform: uppercase;
      color: $brand-gray-46;
      line-height: 1.5rem;
    }

    .schedule-td {
      @include media-breakpoint-up(xl) {
        display: flex;
        justify-content: flex-start;
      }

      h6 {
        margin: 0 0.5rem 0.5rem 0;
        text-transform: uppercase;
        line-height: 2;
        color: $brand-gray-46;
        min-width: 4rem;

        @include media-breakpoint-up(xl) {
          margin-bottom: 0;
        }
      }

      .schedule-pills {
        display: flex;
        flex-wrap: wrap;
        min-width: 110px;
      }
    }
  }
}
