@import "~styles/shared";

.ts {
  .perfect-attendance-summary {
    padding: 1.5rem;
    display: flex;
    align-items: center;

    .pas-icon {
      background: $brand-teal-97;
      padding: 1rem;
      display: block;
      border-radius: 0.5rem;
      margin-right: 2rem;
    }

    .pas-contents {
      h1 {
        margin: 0 0 0.75rem;
        line-height: 1;
      }

      h5 {
        margin: 0;
      }
    }
  }
}
