.ts {
  .results-gauge {
    h1 {
      margin-top: 0.25rem;
      margin-bottom: 1.25rem;
    }

    svg {
      display: block;
      margin: 0 auto -2.25rem;

      .percent {
        stroke-dasharray: 202;
        transition: a11 1s ease;
      }
    }
  }
}
