@import "~styles/shared";

.ts {
  .more-info-container {
    display: flex;
    align-items: center;
  }
  
  .more-info {
    font-size: 1.6em;
    align-self: stretch;
  }
}
