// import fonts
@import "./fonts";
@import "./icons";

// shared gets pulled into component scss that needs vars and mixins
@import "./shared";

// Layout & components
// root needs to be unscoped. Since we are scoping BS, we add it here
@import "node_modules/bootstrap/scss/root";

// the rest of bootstrap is scoped as descendants of the .ts class
// which keeps any new styling from bleeding into the existing components
.ts {
  * {
    transition: padding 0.3s ease-out, margin 0.3s ease-out, width 0.3s ease-out;
  }

  html & {
    font-weight: 500;
  }

  // bring in the pieces of bootstrap we need
  @import "./bootstrap";

  // base overrides -- import in the same order that bootstrap does.
  @import "./base/type";
  @import "./base/containers";
  @import "./base/forms";
  @import "./base/buttons";
  @import "./base/card";
  @import "./base/badge";
  @import "./base/modals";
  @import "./base/tables";
  @import "./base/utilities";
}
