@import "~styles/shared";

.ts {
  .portal-table {
    tr {
      &.portal-row {
        td {
          padding-top: 1.25rem;
          padding-bottom: 1.25rem;
          vertical-align: middle;
        }
      }

      // todo: Seems skeleton and empty views for table data should be shared?
      &.portal-row-skeleton {
        td {
          padding-top: 1.75rem;
          padding-bottom: 1.75rem;
        }
      }

      &.portal-row-empty {
        td {
          padding-top: 5.25rem;
          padding-bottom: 5.25rem;
          vertical-align: middle;
          text-align: center;
        }
      }

      td {
        * {
          margin-bottom: 0;
        }
      }
    }
  }
}
