// Based on TailwindUI Input Groups
// https://tailwindui.com/components/application-ui/forms/input-groups
.field {
  @apply form-input;
  @apply w-full;
  @apply rounded-md;
  @apply shadow-none;
  border-color: #d2d6dc;

  @screen sm {
    @apply text-sm;
    @apply leading-5;
  }

  &:focus {
    @apply outline-none;
    @apply shadow-none;
    border-color: #d2d6dc;
    @apply shadow-outline-blue;
  }

  // States

  &--disabled {
    @apply cursor-not-allowed;
    @apply grayscale;
    @apply bg-gray-100;
    @apply pointer-events-none;
  }

  &--invalid {
    @apply border-red-300;
    @apply text-red-900;

    &:focus {
      @apply border-red-300;
      @apply shadow-outline-red;
    }
  }

  // Subcomponents

  &-set {
    @apply block;

    & + &,
    .field-group + & {
      @apply mt-2;

      .field-group & {
        @apply mt-0;
      }
    }
  }

  &-label {
    @apply block;
    @apply font-medium;
    @apply leading-5;
    @apply text-gray-700;
    @apply text-sm;
    @apply truncate;
  }

  &-hint {
    @apply leading-none;
    @apply text-gray-500;
    @apply text-sm;
  }

  &-group {
    @apply flex;
    @apply mt-0;
    @apply relative;

    & + &,
    .field-set + & {
      @apply mt-2;
    }

    .field-set + .field-set {
      @apply ml-2;
    }
  }

  &-group & {
    @apply mt-0;
  }
}
