@import "~styles/shared";

.ts {
  .card.arbs-card {
    color: $brand-gray-46;
    box-shadow: 2px 2px 2px rgba(95, 99, 104, 0.1), 0 4px 4px rgba(95, 99, 104, 0.05);
    border-radius: 1rem;
    border-color: $brand-gray-94;

  }

  .arbs-chart {
    .recharts-label-list {
      .recharts-text.recharts-label {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      tspan {
        fill: $brand-gray-46;
        font-size: 12px;
      }
    }

    .recharts-cartesian-grid-horizontal,
    .recharts-cartesian-axis-tick-line {
      stroke: $brand-gray-90;

      line {
        stroke: $brand-gray-90;
      }
    }
  }

  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    margin-right: 10px;
  }

  .recharts-rectangle.recharts-tooltip-cursor {
    opacity: 0.2;
  }

  .ts-legend {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }

    li {
      margin: 0.25rem 0.5rem;

      &:last-of-type {
        margin: 0.25rem 0.5rem;
      }
    }

    .legend-square {
      width: 8px;
      height: 8px;
      border-radius: 2px;
      display: block;
      margin: 0 0.25rem 0 0;
    }
  }

  .arbs-no-results {
    padding: 4rem;
    text-align: center;
    border: dashed 1px $brand-gray-94;
    border-radius: 0.25rem;
  }
}
