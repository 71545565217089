@mixin heading {
  @apply font-bold;
}

.prose {
  &-h1 {
    @include heading;
    @apply leading-8;
    @apply text-3xl;
    @apply py-5;
  }

  &-h2 {
    @include heading;
    @apply leading-7;
    @apply text-2xl;
    @apply py-4;
  }

  &-h3 {
    @include heading;
    @apply leading-7;
    @apply py-3;
    @apply text-xl;
  }

  &-h4 {
    @include heading;
    @apply leading-5;
    @apply py-2;
    @apply text-lg;
  }

  &-h5 {
    @include heading;
    @apply leading-5;
    @apply py-1;
  }

  &-p {
    @apply mb-4;
    @apply leading-tight;
  }
}
