@import "~styles/shared";

.ts {
  .attendance-table {
    height: 100%;
    overflow: hidden;

    .employee-info-container {
      display: flex;
      gap: 0.5rem;

      &__left {
        flex: 1;
      }

      &__right {
        flex: 1;
        text-align: right;

        .icon-caret-right {
          font-size: 1.5rem;
        }
      }
    }

    .employee-info {
      @include media-breakpoint-down(lg) {
        display: flex;
        gap: 0.5rem;
      }

      h3 {
        margin-bottom: 0;
      }

      h6 {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        color: $brand-gray-46;
      }
    }

    .status-container {
      display: inline-grid;
    }

    .table {
      > :not(caption) {
        > * {
          /* stylelint-disable-next-line selector-max-compound-selectors */
          > * {
            padding: 0;
          }
        }
      }
    }

    .sync-status {
      text-align: left;
      @include media-breakpoint-down(lg) {
        min-width: 1.25rem;
      }

      img {
        display: inline-block;
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    .event-dates {
      @include media-breakpoint-down(sm) {
        display: block;
        max-width: 180px;
      }

      span {
        display: block;
        white-space: nowrap;
        margin-bottom: 0.25rem;

        @include media-breakpoint-down(lg) {
          display: initial;
          padding-right: 0.5rem;
        }
      }
    }

    // utilities
    .desktop-only-column {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .InovuaReactDataGrid__header {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
