@import "~styles/shared";

.ts {
  .ainr-no-results {
    text-align: center;

    i {
      display: inline-block;
      background: $brand-teal-91;
      margin: 0.5rem auto 1rem;
      line-height: 1;
      border-radius: 50px;
      font-size: 52px;
      padding: 0.5rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }
}
