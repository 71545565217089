.ts {
  .ts-input {
    margin-bottom: 1rem;

    // // fixes safari time input height issue
    .form-control {
      display: flex;
      max-height: 2.5rem;

      &[type="search"] {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7177 16.4304C11.9962 16.4316 13.2378 16.0015 14.2418 15.2097L18.031 19L19 18.0307L15.2108 14.2404C16.008 13.2228 16.4367 11.9649 16.4269 10.6721C16.4172 9.37927 15.9695 8.12799 15.157 7.12254C14.3446 6.11709 13.2153 5.41688 11.9537 5.13626C10.6921 4.85565 9.3726 5.0112 8.21075 5.57751C7.04891 6.14383 6.11333 7.08745 5.55681 8.25429C5.00029 9.42112 4.85571 10.7422 5.14667 12.0019C5.43764 13.2615 6.14695 14.3852 7.15879 15.1896C8.17063 15.9939 9.42521 16.4314 10.7177 16.4304ZM10.7177 6.37166C11.5765 6.37166 12.416 6.6264 13.1301 7.10368C13.8442 7.58096 14.4008 8.25933 14.7294 9.05301C15.0581 9.84669 15.1441 10.72 14.9765 11.5626C14.809 12.4052 14.3954 13.1791 13.7881 13.7866C13.1808 14.394 12.4071 14.8077 11.5648 14.9753C10.7225 15.1429 9.84938 15.0569 9.05593 14.7282C8.26249 14.3994 7.58431 13.8427 7.10718 13.1284C6.63004 12.4141 6.37537 11.5743 6.37537 10.7152C6.37537 9.56324 6.83286 8.45843 7.6472 7.64386C8.46153 6.82928 9.56601 6.37166 10.7177 6.37166Z' fill='%23263D39'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
      }
    }
  }

  ::-webkit-search-cancel-button {
    display: none;
  }
}
