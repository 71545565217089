@import "~styles/shared";

.ts {
  .attendance-insights-ending-soon {
    display: block;
    text-align: center;

    svg {
      margin: 2rem auto;
    }

    .attendance-insights-shrink {
      max-width: 566px;
      margin: 0 auto;
    }

    img {
      width: auto;
      max-width: 1140px;
      margin: 0 auto;
    }
  }
}
