@import "~styles/shared";

.ts {
  .single-bar-row {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 4fr 6fr 1fr;
    gap: 0.5rem;
    align-items: center;

    .sbr-range {
      width: 100%;
      position: relative;

      .sbr-range-full,
      .sbr-range-percent {
        background: $brand-gray-94;
        height: 12px;
        width: 100%;
        border-radius: 10px;
        position: relative;
        left: 0;
      }


      .sbr-range-percent {
        transition: background-color 0.1s ease-in;
        position: absolute;
        min-width: 12px;
      }
    }

    .sbr-label {
      overflow: hidden;

      h6 {
        margin: 0 0 0.125rem;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.2;
      }

      p {
        margin: 0;
        letter-spacing: 0;
        text-align: left;
        font-size: 0.75rem;
        font-weight: 500;
        color: $brand-gray-46;
        line-height: 1.2;
      }
    }

    .sbr-value {
      font-size: 0.75rem;
      font-weight: 700;
      text-align: right;
    }
  }

  .attendance-popovers.popover {
    border-color: $brand-gray-94;
    box-shadow: 2px 2px 2px rgba(95, 99, 104, 0.1), 0 4px 4px rgba(95, 99, 104, 0.05);


    .popover-arrow {
      &::before {
        border-top-color: $brand-gray-94;
      }
    }
  }
}
