* {
  letter-spacing: 0.5px;
}

h1,
.h1 {
  line-height: 2rem;
}

h2,
.h2 {
  line-height: 1.5rem;
}

h3,
.h3 {
  line-height: 1.5rem;
}

h4,
.h4 {
  line-height: 1rem;
}

h5,
.h5 {
  font-weight: 500;
  line-height: 1rem;
}

h6,
.h6 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1rem;
}

p {
  font-weight: 500;
  line-height: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.data {
  font-size: 2rem;
  line-height: 2.5rem;
}

.caption {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}
