@import "~styles/shared";

.ts {
  .calendar-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.single-day {
      border-radius: 50%;
    }

    &.multi-day {
      &--start {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &--span {
        box-shadow: 0.5rem 0 0;
      }

      &--end {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }

    &.ongoing {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
