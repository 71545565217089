@import "~styles/shared";

.ts {
  .toast-container {
    margin-top: 1rem;
    padding: 0;
    z-index: $zindex-tooltip;

    > :not(:last-child) {
      margin-bottom: 1.125rem !important;
    }

    &.position-absolute {
      position: fixed !important;
    }
  }

  .toast {
    background-color: $brand-gray-20;
    width: 25.5rem;

    .toast-body {
      border-top: 0.5rem solid $ui-blue-2;
    }

    &.bg-success {
      .toast-body {
        border-top: 0.5rem solid $ui-green-2;
      }
    }

    &.bg-danger {
      .toast-body {
        border-top: 0.5rem solid $ui-red-2;
      }
    }

    .icon-close {
      position: absolute;
      font-size: 1.5rem;
      cursor: pointer;
      color: $brand-gray-90;
      right: 0.25rem;
      top: 0.4rem;
    }

    .toast-body {
      position: relative;
      border-radius: 0.25rem;
      background-color: $brand-gray-20;
      text-align: center;
      color: $white;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.375rem;
      padding: 1.5rem 3.5rem;
    }
  }
}
