@import "~styles/shared";

.ts {
  .confetti-animation-background {
    position: absolute;
    background: url("../../images/confetti-on-transparent-background.gif") no-repeat;
    background-size: auto;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
}
