@import "~styles/shared";

.ts {
  // Colors
  $rbt-color-primary: $primary !default;
  $rbt-color-disabled: #495057 !default;
  $rbt-color-white: #fff !default;

  /**
 * Multi-select Input
 */
  $rbt-background-color-disabled: $input-disabled-bg !default;

  $rbt-border-color-focus: $primary !default;
  $rbt-border-color-focus-invalid: $danger !default;
  $rbt-border-color-focus-valid: $success !default;

  $rbt-box-shadow-dimensions: 0 1px 8px;
  $rbt-box-shadow-color: rgba($ui-input-focus, 0.6) !default;
  $rbt-box-shadow-color-invalid: rgba($danger, 0.6) !default;
  $rbt-box-shadow-color-valid: rgba($success, 0.6) !default;

  $rbt-color-focus: $primary !default;
  $rbt-placeholder-color: $input-placeholder-color !default;

  /**
 * Token
 */
  $rbt-token-background-color: $brand-gray-94 !default;
  $rbt-token-color: $brand-gray-20 !default;

  $rbt-token-disabled-background-color: $rbt-token-background-color !default;
  $rbt-token-disabled-color: $rbt-token-color !default;

  $rbt-token-active-background-color: $input-color !default;
  $rbt-token-active-color: $rbt-color-white !default;

  // Add bootstrap select input caret
  .rbt-input {
    &.form-control {
      background-image: escape-svg($form-select-indicator);
      background-position: $form-select-bg-position;
      background-repeat: no-repeat;
      background-size: $form-select-bg-size;
    }
  }

  // Hide IE's native "clear" button
  .rbt {
    .rbt-input-main::-ms-clear {
      display: none;
    }
  }

  /**
 * Menu
 */
  .rbt-menu {
    border-radius: $input-border-radius;
    box-shadow: $box-shadow-lg;
    margin-bottom: 2px; // Spacing for dropup

    > .dropdown-item {
      white-space: normal;

      &:focus {
        outline: none;
      }
    }

    &-pagination-option {
      text-align: center;
    }
  }

  /**
 * Multi-select Input
 */

  .rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
    padding-right: 2.5rem;

    // Apply Bootstrap focus styles
    &.focus {
      border-color: $rbt-border-color-focus;
      // 0 1px 8px rgba($ui-input-focus, 0.6);
      box-shadow: $input-focus-box-shadow;
      color: $rbt-color-focus;
      outline: 0;
    }

    &.form-control {
      height: auto;
    }

    // BS4 uses the :disabled pseudo-class, which doesn't work with non-inputs.
    &.form-control[disabled] {
      background-color: $rbt-background-color-disabled;
      opacity: 1;
    }

    &.is-invalid.focus {
      border-color: $rbt-border-color-focus-invalid;
      box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-invalid;
    }

    &.is-valid.focus {
      border-color: $rbt-border-color-focus-valid;
      box-shadow: $rbt-box-shadow-dimensions $rbt-box-shadow-color-valid;
    }

    // Apply Bootstrap placeholder styles
    input {
      // Firefox
      &::placeholder {
        color: $rbt-placeholder-color;
        opacity: 1;
      }

      // Internet Explorer 10+
      &:input-placeholder {
        color: $rbt-placeholder-color;
      }

      // Safari and Chrome
      // stylelint-disable
      &::input-placeholder {
        color: $rbt-placeholder-color;
      }

      // stylelint-enable
    }

    .rbt-input-wrapper {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -4px;
      margin-top: -1px;
      overflow: hidden;
    }

    .rbt-input-main {
      margin: 1px 0 4px;
      line-height: 2rem;
    }
  }

  /**
 * Close Button
 */
  .rbt-close {
    z-index: 1;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-left: 0.25rem;
    width: 1.5rem;
    margin-top: -0.15rem;
    
    &-lg {
      font-size: 24px;
    }
  }

  /**
 * Token
 */
  .rbt-token {
    background-color: $rbt-token-background-color;
    border: 0;
    border-radius: 500px;
    color: $rbt-token-color;
    display: inline-flex;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 2.4;
    margin: 1px 4px 4px 0;
    padding: 2px 12px;
    position: relative;
    align-items: center;

    &-disabled {
      background-color: $rbt-token-disabled-background-color;
      color: $rbt-token-disabled-color;
      pointer-events: none;
    }

    &-removeable {
      cursor: pointer;
      padding-right: 8px;
    }

    &-active {
      background-color: $rbt-token-active-background-color;
      color: $rbt-token-active-color;
      outline: none;
      text-decoration: none;
    }

    & &-remove-button {
      bottom: 0;
      color: inherit;
      font-size: inherit;
      font-weight: normal;
      opacity: 1;
      outline: none;
      padding: 3px 7px;
      position: absolute;
      right: 0;
      text-shadow: none;
      top: -2px;
    }
  }

  /**
 * Loader + CloseButton container
 */
  .rbt-aux {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    pointer-events: none; /* Don't block clicks on the input */
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;

    &-lg {
      width: 46px;
    }

    .rbt-close {
      margin-top: -4px;
      pointer-events: auto; /* Override pointer-events: none; above */
    }
  }

  .has-aux {
    .form-control {
      padding-right: 32px;

      // Position validation icons next to clear button and loader.
      &.is-valid,
      &.is-invalid {
        background-position: right 2rem center;
        padding-right: 64px;
      }
    }
  }

  // Default highlight style
  .rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
  }

  /**
 * Input Groups
 */
  .input-group {
    > .rbt {
      flex: 1;

      // Form-controls within input-groups have a higher z-index.
      .rbt-input-hint,
      .rbt-aux {
        z-index: 5;
      }

      &:not(:first-child) .form-control {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      &:not(:last-child) .form-control {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  // Need to override styles to enforce disabled properties
  .disable-by-permission {
    .rbt-input-multi {
      &.form-control {
        background-color: $rbt-background-color-disabled;
        cursor: default;
      }
    }
  }
}
