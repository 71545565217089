@import "~styles/shared";

.ts {
  ul.rc-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    padding: 0;

    .rc-pagination {
      &-item,
      &-prev,
      &-next {
        color: $brand-teal-19;
        display: block;
        height: 32px;
        min-width: 32px;
        text-align: center;
        background: $brand-teal-91;
        line-height: 2rem;
        border-radius: 1rem;
        margin: 0 0.125rem;
        transition: all 0.15s ease-in;

        &:hover {
          cursor: pointer;
          background: $brand-teal-39;
          color: $white;
        }
      }

      &-prev {
        &.rc-pagination-disabled {
          color: $brand-teal-91;
          background: transparent;
        }

        &:hover {
          cursor: default;
        }

        button::after {
          content: "‹";
          display: block;
          font-size: 1rem;
        }
      }

      &-next {
        &.rc-pagination-disabled {
          color: $brand-teal-91;
          background: transparent;
        }

        button::after {
          content: "›";
          display: block;
          font-size: 1rem;
        }
      }


      &-jump-next,
      &-jump-prev {
        display: block;
        height: 32px;
        margin: 0 0.5rem;

        button::after {
          display: block;
          content: "…";
        }
      }

      &-item-active {
        background: $brand-teal-39;
        color: $white;
      }
    }
  }
}
