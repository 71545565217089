@import "~styles/shared";

.ts {
  .heatmap-calendar {
    max-width: 270px;
    margin: 0 auto;

    .react-calendar__month-view {
      &__days,
      &__weekdays {
        justify-content: center;
      }

      &__weekdays {
        margin-bottom: 0.5rem;

        &__weekday {
          text-align: center;

          abbr {
            text-decoration: none;
            cursor: default;
            font-weight: 700;
            color: $brand-gray-60;
          }
        }
      }

      &__days {
        &__day--neighboringMonth {
          .heatmap-tile {
            color: $brand-gray-77;
          }
        }
      }
    }

    .react-calendar__navigation {
      margin-bottom: 0.5rem;
      text-align: center;

      &__prev2-button,
      &__prev-button,
      &__next2-button,
      &__next-button {
        display: none;
      }

      &__label {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0.5rem 0;
        color: $brand-gray-46;
      }
    }

    .react-calendar__tile {
      margin: 0.25rem auto;

      &:hover {
        cursor: default;
      }

      abbr {
        position: absolute;
        z-index: -1;
      }
    }

    .heatmap-tile {
      width: 32px;
      height: 32px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      color: $brand-gray-60;
      border-radius: 16px;
    }
  }
}
