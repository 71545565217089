.ts {
  .title-empty-attendances {
    text-align: center;
    padding: 4rem 0;
    line-height: 32px;
      
    .icon-hands {
      font-size: 60px;
      display: block;
      margin-bottom: 25px;
    }
  }
}
