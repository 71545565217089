@import "~styles/shared";

.ts {
  .date-button {
    display: flex;
    flex-direction: row;
    background-color: $brand-teal-39;
    color: $white;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem;
    justify-content: space-between;
    min-width: 10rem;

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}

/* stylelint-disable selector-class-pattern */
.attendance-container-picker {
  .react-datepicker {
    padding: 1rem;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    margin-top: 10px;
  }

  .react-datepicker__header {
    background-color: $white;
    border-bottom-width: 0;
    border-bottom-style: none;

    .react-datepicker__current-month {
      font-weight: normal;
      border-bottom: 1px solid $brand-gray-77;
      padding: 0.5rem;
      color: $brand-gray-35;
    }

    .react-datepicker__day-name {
      color: $brand-gray-60;
    }
  }

  .react-datepicker__day {
    border-radius: 999px;

    &.react-datepicker__day--outside-month {
      color: $brand-gray-60;
    }
  
    &.react-datepicker__day--today {
      background-color: $brand-teal-39;
    }

    &.react-datepicker__day--selected,
    &.react-datepicker__day--keyboard-selected {
      background-color: $brand-teal-56;
      color: $white;
    }

    &:hover {
      background-color: $brand-teal-78;
      border-radius: 999px;
    }
  }
}

@include media-breakpoint-down(md) {
  .react-datepicker-popper.properAttendanceContainer {
    top: 150px !important;
    left: 0 !important;
    transform: none !important;
    width: 100% !important;
    z-index: 0 !important;
    margin-top: 10px;
  }

  .attendance-container-picker {
    .react-datepicker {
      width: 100%;

      .react-datepicker__month-container {
        width: 100%;
      }
    } 
  }
}

@include media-breakpoint-down(sm) {
  .react-datepicker-popper.properAttendanceContainer {
    top: 198px !important;
    left: 0 !important;
    transform: none !important;
    width: 100% !important;
  }
}
