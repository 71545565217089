.ts {
  .required-header-label {
    margin-bottom: 2rem;
    display: block;
  }

  .row {
    margin-bottom: 1rem;
  }

  .edit-employee-modal-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .rbt.has-aux {
    .rbt-input.form-control {
      background-image: none;
    }
  }

  .async-search {
    .rbt-input {
      &.form-control {
        background-image: none;
      }
    }
  }
}
