@import "~styles/shared";

.ts {
  .attendance-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;

    @include media-breakpoint-down(md) {
      justify-content: flex-start;

      .attendance-export-button {
        display: none;
      }
    }

    .fw-filter {
      background: $white;
      height: 2.575rem;
      border-radius: 0.5rem;

      button {
        height: 2.575rem;
        border: 1px solid $brand-gray-77;

        .filter-icon {
          margin-right: -0.5rem;
        }
      }

      @include media-breakpoint-down(md) {
        button {
          padding-right: 0.725rem;
          padding-left: 0.725rem;

          .filter-icon {
            margin: 0;
          }
        }

        span {
          display: none;
        }
      }
    }
  }

  .attendance-page-header {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 1rem;
    align-content: center;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      flex-wrap: nowrap;
    }

    h1 {
      width: 100%;
      @include media-breakpoint-up(sm) {
        margin: 0;
        width: auto;
      }
    }

  }
}
