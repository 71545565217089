@import "~styles/shared";

.ts {
  .attendance-details-new {
    box-shadow: 0 0 $black, 0 0 $black, $box-shadow-md;

    //Details header
    .card-header {
      background: $gray-200;

      .employee-name {
        &a {
          color: $brand-teal-39;
          text-decoration: underline;
          cursor: pointer;
        }
  
        &a:hover {
          color: $brand-teal-19;
        }
      }

      .employee-data {
        color: $brand-gray-35;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .label-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        .label-group-column {
          text-align: right;
        }
      }
    }

    //Details Body
    .details-data {
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-bottom: 0.25rem;
      padding-left: 2rem;
      padding-right: 2rem;

      .event-block {
        margin-top: 0;
        margin-bottom: 0;
      }
      
      .spinner {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
      }
    }

    .event-block .event-header {
      margin-left: 0;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      line-height: 1rem;

      .event-dates {
        margin-left: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
        color: rgb(139 139 139 / 1);
      }
    }

    .event-block .event-subheader {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $brand-gray-46;
    }
    
    .event-block .survey-steps {
      margin-top: 1rem;
      margin-bottom: 1rem;
      list-style-position: outside;
      list-style-type: decimal;
      padding-left: 1rem;

      .step {
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }

    .step .step-question {
      display: block;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      color: $brand-gray-46;
    }

    .step .step-answer {
      display: block;
      padding: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: none;
    }
  }
}
