// Based on TailwindUI Modals
// https://tailwindui.com/components/application-ui/overlays/modals
.tailwind-modal {
  @apply bg-white;
  @apply pb-4;
  @apply pt-5;
  @apply px-4;
  @apply rounded-lg;
  @apply shadow-xl;
  @apply transform;
  @apply transition-all;

  @screen sm {
    @apply max-w-sm;
    @apply p-6;
    @apply w-full;
  }
}

.tailwind-modal-overlay {
  @apply bg-darken-200;
  @apply fixed;
  @apply pb-6;
  @apply px-4;

  @apply flex;
  @apply inset-0;
  @apply items-center;
  @apply justify-center;
  @apply z-10;

  @screen sm {
    @apply p-0;
  }
}
