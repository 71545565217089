@import "~styles/shared";

.ts {
  .communicate-row {
    cursor: pointer;

    td {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      vertical-align: middle;

      h3,
      h5,
      h6 {
        margin-bottom: 0;
      }

      h5,
      h6 {
        color: $brand-gray-46;
      }
    }
  }
}


