@import "~styles/shared";

.ts {
  .absence-gauge {
    text-align: center;

    h1 {
      font-size: 2rem;
    }

    svg {
      display: block;
      margin: 0 auto -2.25rem;

      .percent {
        //stroke-dasharray: 202;
      }
    }
  }
}
