/* stylelint-disable selector-no-qualifying-type */
div.datepicker.default {
  background-color: #fff;
  font-family: inherit;
  position: relative;
}

div.datepicker.default div.datepicker-wheel {
  border-bottom: 1px solid #01c16b;
  border-top: 1px solid #01c16b;
}

div.datepicker.default ul.datepicker-scroll li {
  color: #263d39;
  font-size: inherit;
}

div.datepicker.default div.datepicker-caption-item {
  color: #263d39;
  font-size: inherit;
  font-weight: bold;
}
