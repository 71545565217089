@import "~styles/shared";

.ts {
  .absence-level-0 {
    color: $brand-teal-91;
    background-color: $brand-teal-91;
  }

  .absence-level-1 {
    color: $brand-teal-78;
    background-color: $brand-teal-78;
  }

  .absence-level-2 {
    color: $brand-teal-56;
    background-color: $brand-teal-56;
  }

  .absence-level-3 {
    color: $brand-teal-39;
    background-color: $brand-teal-39;
  }

  .absence-level-4 {
    color: $brand-teal-19;
    background-color: $brand-teal-19;
  }
}
