@import "~styles/shared";

.ts {
  .attendance-legend-item {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 0.75rem;
    white-space: nowrap;
    margin: 0 0 0.5rem;
    color: $brand-gray-35;

    .legend-square {
      width: 8px;
      height: 8px;
      border-radius: 0.125rem;
      display: block;
      margin: 0 0.25rem 0 0;
    }
  }
}
