@import "~styles/shared";

.ts {
  .sent-message {
    background: $brand-teal-97;
    padding: 1.5rem 1rem;
    border-radius: 0.5rem;
    margin: 0 0 2rem;
    color: $brand-gray-35;
  }

  .sent-message .subject {
    font-weight: 600;
  }

  .sent-message .body {
    font-weight: 500;
  }

  .sent-message-log-table {
    tr {
      &.row-skeleton {
        td {
          padding-top: 1.75rem;
          padding-bottom: 1.75rem;
        }
      }
    }

    td {
      * {
        margin-bottom: 0;
      }
    }
  }
}
