/* TSTable.scss */
@import "~styles/shared";

/* .table */
.table {
  border-radius: 0;
  background-color: $white;
  vertical-align: middle;

  .icon-caret-top,
  .icon-caret-bottom {
    margin-left: 0.5rem;
    font-size: 0.75rem;

    &--hide {
      display: none;
    }

    &--active {
      display: block;
    }
  }

  .icon-caret-bottom--hide {
    display: none;
  }

  .icon-caret-top--hide {
    display: none;
  }
}

.InovuaReactDataGrid {
  &__header {
    background: $brand-gray-98 !important;
    color: $brand-gray-46;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.05em !important;
    text-transform: uppercase;
    font-weight: 700 !important;

    &-layout {
      padding: 0 !important;
    }
  }

  &__row {
    &--selected {
      background: $brand-gray-98 !important;
    }
  }

  &__column-header {
    &__content {
      padding: 1rem 1rem !important;
      background-color: var(--bs-table-bg);
      box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }

    &--show-border-left {
      /* stylelint-disable-next-line declaration-property-value-disallowed-list */
      border-left: none !important;
    }
  }

  &__cell {
    &__content {
      padding: 8px;
    }
  }
}

.global-custom-row:hover,
.global-custom-row:hover .InovuaReactDataGrid__cell {
  background: $brand-gray-98 !important;
}

.InovuaReactDataGrid__column-header--sort-asc,
.InovuaReactDataGrid__column-header--sort-desc {
  font-weight: bold;
  color: $brand-teal-19;

  .icon-caret-top,
  .icon-caret-bottom {
    font-weight: bold;
    color: $brand-teal-19;
  }
}

.InovuaReactDataGrid__column-header:hover .icon-caret-top--hide {
  display: none;
}

.InovuaReactDataGrid__column-header:hover .icon-caret-bottom--hide {
  display: block;
}

.InovuaReactDataGrid__column-header--sort-asc:hover .icon-caret-top--hide {
  display: block;
}

.InovuaReactDataGrid__column-header--sort-asc:hover .icon-caret-bottom--active {
  display: none;
}

.InovuaReactDataGrid__column-header--sort-asc:hover .icon-caret-bottom--hide {
  display: none;
}

.InovuaReactDataGrid__column-header--sort-desc:hover .icon-caret-bottom--hide {
  display: block;
}

.InovuaReactDataGrid__column-header--sort-desc:hover .icon-caret-top--hide {
  display: none;
}

.InovuaReactDataGrid__column-header--sort-desc:hover .icon-caret-top--active {
  display: none;
}
