@import "~styles/shared";

.ts {
  .form-results-filters {
    justify-content: space-between;
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .form-quick-results {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .quick-stat {
    text-align: center;

    &:last-of-type {
      grid-column: 1 / 3;

      @include media-breakpoint-up(lg) {
        grid-column: unset;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    i {
      font-size: 2rem;
      color: $brand-teal-39;
    }

    h1 {
      margin: 0.25rem 0 0.5rem 0;
      font-size: 2rem;
    }

    p {
      margin: 0;
      font-size: 0.8rem;
      color: $gray-800;
    }
  }

  .form-question-set {
    @include media-breakpoint-up(xl) {
      max-width: 70%;
    }
  }

  .form-skip-explanation {
    font-weight: 400;
    padding: 1rem;
    background-color: $brand-teal-97;
  }

  .form-answer {
    display: grid;
    grid-template-columns: 6fr 4fr 2fr;
    gap: 1rem;
    margin: 0 0 0.75rem;
    align-items: center;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    line-height: 1rem;

    .form-response {
      color: $brand-gray-46;
    }

    .form-progress {
      .progress {
        height: 12px;

        .progress-bar {
          border-radius: 100px;
          background-color: $ui-green-2;
        }
      }
    }
  }

  .form-results-text-table {
    max-height: 280px;
    overflow: scroll;
    border: solid 1px $brand-gray-77;

    .table {
      margin: 0;
    }
  }

  .form-results-no-responses {
    padding: 2rem 0;

    img {
      margin: 0 auto 2rem auto;
    }

    h1 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;

      @include media-breakpoint-up(md) {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 6rem;
        margin-right: 6rem;
      }

      @include media-breakpoint-up(xxl) {
        margin-left: 12rem;
        margin-right: 12rem;
      }
    }
  }
}

.date-picker-fw {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .btn {
    justify-content: space-between;
  }
}
