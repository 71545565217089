@import "~styles/shared";

.ts {
  .attendance-insights-header {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;

      h1 {
        margin: 0;
        display: flex;
        align-items: center;
      }
    }

    .badge.beta-pill {
      font-size: 0.75rem;
      margin-left: 1rem;
    }
  }

  .attendance-insights-filter-buttons {
    display: flex;
  }

  .attendance-insights-filter-group {
    border-bottom: solid 1px $brand-gray-94;
    margin: 0.5rem 0 1rem;

    &:last-of-type {
      border-bottom: 0;
    }

    label {
      margin: 0 0 1rem;
      font-size: 0.875rem;
      font-weight: 600;
    }

    .colorized-token {
      &:active {
        color: #fff !important;
        background: $brand-gray-35 !important;
      }
    }

    .form-control.rbt-input-multi {
      padding-right: 1.5rem;
    }
  }

  .card.attendance-insights-card {
    box-shadow: 2px 2px 2px rgba(95, 99, 104, 0.1),
      0 4px 4px rgba(95, 99, 104, 0.05);
    border-radius: 1rem;
    border-color: $brand-gray-94;
    color: $brand-gray-46;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0s;

    &.aligned {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.opacity-25 {
      transition-delay: 1s;
    }

    h3 {
      font-size: 1rem;
      color: $brand-gray-46;
      font-weight: 600;
      margin: 0.5rem 0;
    }

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.2;
      color: $brand-gray-35;
    }
  }

  .thinking-animation {
    transition: all 0.3s ease-in;
  }

  .quick-stats {
    column-span: 12;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(lg) {
      column-span: 4;
    }
  }

  .attendance-insights-small-charts {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
    }

    &.triple {
      @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  .attendance-insights-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .animate-insight {
    transition: all 0.2s ease-in-out;
  }
}
