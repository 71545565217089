@import "~styles/shared";

.demo-typography {
  td {
    padding: 1.5rem !important;
  }
}

.demo-icons {
  .col {
    flex: 0 0 11rem;
    text-align: center;
    margin: 0.5rem;

    > div {
      color: $gray-600;
      font-size: 1rem;
    }

    i {
      font-size: 2rem;
      line-height: 4rem;
    }
  }
}
