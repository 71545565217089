@import "~styles/shared";

.ts {
  .heatmap-monthly-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
  }


  .attendance-heatmap-legend {
    display: flex;
    justify-content: center;
    margin: 1rem;
    flex-wrap: wrap;

    > div {
      margin: 0 0.5rem;
    }
  }
}
