.status-graph-container {
  @apply bg-white;
  @apply overflow-auto;

  &::-webkit-scrollbar {
    appearance: none;
    height: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
