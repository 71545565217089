@import "~styles/shared";

.ts {
  .dropdown-group {
    margin-right: 1rem;

    .dropdown-toggle-button {
      min-width: 10.5rem;
      padding-right: 0.8rem;
      padding-left: 0.8rem;
    }

    .dropdown-menu {
      padding: 0;
    }

    .dropdown-item {
      padding: 0.35rem 0 0.65rem 0;

      p {
        margin-right: 0.8rem;
        margin-left: 0.8rem;
      }

      &:active {
        background-color: $brand-teal-91;
      }
    }

    .dropdown-item:first-child {
      padding: 0.8rem 0 0 0;
    }

    .dropdown-text {
      color: #555e5c;
      margin: 0;
      line-height: 1.5rem;
    }

    .calendar-icon {
      margin-right: 0 !important;
      // color: #263d39 !important;
    }

    .dropdown-sub-text {
      font-size: smaller;
      margin: 0;
      color: $brand-gray-46;
      line-height: 1rem;
    }

    .dropdown-divider {
      margin: 0.8rem 0 0 0;
    }
  }
}
