.card {
  &.content-block {
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.card-body {
  padding: 2rem;
}
