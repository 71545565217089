@import "~styles/shared";

.ts {
  @supports (-moz-appearance: none) {
    .form-select {
      option:disabled {
        color: $brand-gray-60;
      }
    }
  }

  .reminder-duration {
    color: $brand-gray-46;
    display: flex;
    align-items: center;

    .badge {
      &.reminder-badge {
        text-transform: capitalize;
        margin-bottom: 0;
      }
    }
  }

  .confirm-delete-modal {
    p {
      margin-top: 2rem;
    }
  }

  .reminder-duration {
    color: $brand-gray-46;
    display: flex;
  }

  .reminder-duration-length {
    min-width: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 2rem;
    }
  }

  .reminders-modal-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
