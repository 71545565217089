@import "~styles/shared";

.ts {
  .recharts-tooltip-wrapper {
    outline: none;
    padding: 0.75rem;
    min-width: 160px;
    background: #fff;
    border: 1px solid #eff0f0;
    border-radius: 0.25rem;
    box-shadow: 2px 4px 4px rgba(95, 99, 104, 0.1),
      0 8px 8px rgba(95, 99, 104, 0.05);
  }

  .arbs-infocard {
    .arbs-status-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 0.75rem;
    }
  }

  .arbs-status {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 0.75rem;
    white-space: nowrap;
    margin: 0 0 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    .legend-square {
      width: 8px;
      height: 8px;
      border-radius: 0.125rem;
      display: block;
      margin: 0 0.25rem 0 0;
    }
  }

  .arbs-status-value {
    font-weight: 700;
    margin-left: 0.5rem;
  }
}
