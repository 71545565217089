@import "~styles/shared";

.ts {
  .ts-pill {
    &.badge {
      color: $brand-gray-20;
      background-color: $brand-gray-94;

      &.badge-info {
        color: $ui-blue-3;
        background-color: $ui-blue-0;
      }

      &.badge-success {
        background-color: $brand-teal-39;
        color: #fff;
      }

      &.badge-warning {
        background: $ui-yellow-0;
        color: $ui-yellow-3;
      }
    }
  }
}
