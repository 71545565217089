.table {
  border-radius: 0;
  background-color: $white;
  vertical-align: middle;

  thead {
    th,
    button {
      background: $gray-200;
      color: $gray-700;
      font-size: $h6-font-size;
      line-height: 1rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  > :not(:first-child) {
    border-top: 2px solid $table-border-color;
  }

  .badge {
    margin: 0 0.25rem 0.25rem 0;
    line-height: 1rem;
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  }
}

.standard-page {
  .infinite-scroll-component {
    overflow: visible !important;
  }

  .table-responsive {
    overflow: visible !important;
  }

  table {
    border: 1px solid $table-border-color;
    border-color: $table-border-color;
  }
}

@media (max-width: 768px) {
  .standard-page {
    .infinite-scroll-component {
      overflow: auto !important;
    }

    .table-responsive {
      overflow-x: scroll;

      table {
        thead {
          .table-dropdown {
            position: fixed !important;
            z-index: 2000;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            max-height: 90% !important;
            min-width: 50%;
            min-height: 300px;
          }
        }
      }
    }
  }
}
