.content-card {
  @apply bg-white
    border
    border-hs-alt-gray
    px-10
    py-8
    my-8;

  &.card-border-radius {
    border-radius: 1rem;
  }
  
  .content-card-heading {
    @apply mb-4
      text-2xl
      font-semibold
      text-hs-dark;
  }
}
