@import "~styles/shared";

.ts {
  @import "react-phone-input-2/lib/bootstrap";

  .react-tel-input {
    .form-control {
      font-family: $font-family-sans-serif;
      width: 100%;
      padding-left: 4.25rem;
      color: $input-color;

      &:focus {
        border-color: $primary;
        box-shadow: $input-btn-focus-box-shadow;

        &.invalid-number {
          border-color: $danger;
        }
      }

      &.invalid-number {
        border: 1px solid $danger;
      }
    }

    .flag-dropdown {
      &:focus {
        box-shadow: $input-btn-focus-box-shadow;
      }
    }

    .selected-flag {
      width: 3.75rem;
      padding-left: 0.75rem;
      border-right: 1px solid $input-border-color;

      &::before {
        top: 0;
        bottom: 0;
        border: transparent 1px solid;
      }

      &:focus,
      &.open {
        &::before {
          border: $primary 1px solid;
          box-shadow: $input-btn-focus-box-shadow;
        }
      }

      .arrow {
        margin-top: 0;
        left: 2rem;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $input-color;

        &.up {
          border-top: unset;
          border-bottom: 4px solid $input-color;
        }
      }
    }
  }
}
