@import "~styles/shared";

.ts {
  .date-picker-fw {
    width: 100%;

    .btn {
      justify-content: space-between;
    }
  }

  .react-datepicker {
    padding: 7rem 2rem 3.5rem 2rem;
    width: 100%;

    &__header {
      background-color: transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    &__month-container {
      pointer-events: auto;
    }

    &__day-names {
      margin-top: 4.5rem;
    }

    &__day {
      border-radius: 50%;
      background: #f9f9f9;

      &:hover {
        border-radius: 50%;
      }

      &--in-range {
        background-color: $brand-teal-39;

        &:hover {
          background-color: $brand-teal-19;
        }
      }

      &--selected {
        background-color: $brand-teal-39;

        &:hover {
          background-color: $brand-teal-19;
        }
      }

      &--keyboard-selected {
        background-color: $brand-teal-78;
      }

      &--today {
        background-color: $brand-teal-78;
        font-weight: bolder;
        color: #000000;

        &:hover {
          background-color: $brand-teal-78;
        }
      }

      &--in-selecting-range {
        background-color: $brand-teal-78;

        &:hover {
          background-color: $brand-teal-19;
        }
      }

      &--selecting-range-start {
        background-color: $brand-teal-19;
      }

      &--selecting-range-end {
        background-color: $brand-teal-19;
      }

      &--in-range {
        border-radius: 50%;
      }
    }
  }

  .custom-range-picker-controls {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .custom-header-top {
      pointer-events: auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .custom-header-bottom {
      pointer-events: auto;
      position: absolute;
      bottom: 1rem;
      right: 2rem;
    }

    .month-and-year {
      display: inline-block;
    }
  }
}
